
export function init () {
    function initAccessiway() {
        (function(){
            var s = document.createElement('script'),
                e = !document.body ? document.querySelector('head') :
                    document.body;
            s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
            s.async = true;
            s.dataset['cookieconsent'] = 'ignore';
            s.onload = function(){
                acsbJS.init({
                    statementLink : '',
                    footerHtml :  '<a  href="https://www.accessiway.com/" target="_blank">AccessiWay. The Web Accessibility Solution</a>',
                    hideMobile : false,
                    hideTrigger : false,
                    language : _config.lang,
                    position : 'left',
                    leadColor : '#2b2171',
                    triggerColor : '#2b2171',
                    triggerRadius : '50%',
                    triggerPositionX : 'right',
                    triggerPositionY : 'bottom',
                    triggerIcon : 'people',
                    triggerSize : 'medium',
                    triggerOffsetX : 20,
                    triggerOffsetY : 20,
                    mobile : {
                        triggerSize : 'medium',
                        triggerPositionX : 'right',
                        triggerPositionY : 'bottom',
                        triggerOffsetX : 10,
                        triggerOffsetY : 10,
                        triggerRadius : '50%'
                    }
                });
            };
            e.appendChild(s);
        }());
    }

    console.log("init accessiway");
    initAccessiway();
}

